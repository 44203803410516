import React from 'react';

import Layout from 'components/layout/Layout';
import SEO from 'components/seo';
import AboutMeJumbotron from 'components/about-me-jumbotron/AboutMeJumbotron';

const AboutMe = () => (
  <Layout>
    <SEO title="About Me" />
    <AboutMeJumbotron />
  </Layout>
);

export default AboutMe;
