import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import './AboutMeJumbotron.scss';

const AboutMeJumbotron = () => {
  const { aboutMePicQuery } = useStaticQuery(graphql`
    query {
      aboutMePicQuery: allFile(
        filter: { absolutePath: { regex: "/(about-me.jpg)$/" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 500, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const aboutMePic = aboutMePicQuery.edges[0].node;

  return (
    <div className="about-me-jumbotron">
      <div className="left">
        <div className="about-me-container">
          <div className="about-me">ABOUT ME</div>
        </div>
        <Img
          fluid={aboutMePic.childImageSharp.fluid}
          fadeIn={true}
          alt={aboutMePic.name}
          draggable={false}
          className="about-me-pic"
        />
      </div>
      <div className="right">
        <div className="credentials">
          <span className="heading">Credentials</span>
          <div className="credentials-wrapper">
            <ul className="section">
              <li>
                ‣ <b>Bachelor of Economics</b> at National University of
                Singapore (NUS)
              </li>
              <li>
                ‣ Educator Since <b>2016</b>
              </li>
              <li>
                ‣ Coached over <b>100 students</b>
              </li>
            </ul>
            <ul className="section">
              <li>
                ‣ <b>90% A/B</b> among past students at O/A Levels
              </li>
              <li>
                ‣ <b>A1</b> for A/E Maths at OLevels
              </li>
              <li>
                ‣ <b>Distinction</b> for H2 Maths/Economics at ALevels
              </li>
            </ul>
          </div>
        </div>
        <div className="teaching-philosophy">
          <span className="heading">Teaching Philosophy</span>
          <div className="content">
            <p>
              Being an <b>educator since 2016</b> has allowed me to be exposed
              to students with a spectrum learning aptitudes - and that no one
              method suits all. Students of{' '}
              <b>similar caliber will be grouped</b> and this has proven to be
              effective given the significant improvement my past batches had
              shown.
            </p>
            <p>
              Having access to the materials from a wide array of{' '}
              <b>top schools</b>, I have the upper hand of being able to{' '}
              <b>fine-tune my materials</b> that work best for my students. I
              truly believe in ensuring a strong mathematical and economical
              foundation among my students. I often{' '}
              <b>
                relate theoretical concepts learned in textbooks to real-life
                situations
              </b>{' '}
              around us. I believe in cultivating an understanding among my
              students that <b>extends beyond the curriculum</b>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMeJumbotron;
